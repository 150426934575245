import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { AudioPlayerStyles } from "../components/styles/AudioPlayerStyles"

const StyledThing = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-top: 100px;
  background-color: gray;
  border: 2px solid black;

  #rj-cover {
    width: 150px;
    height: 150px;
    background-color: blue;
  }
`

const AudioPlayer = () => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <AudioPlayerStyles>
        <div id="rj-player">
          <div class="player-v3 player-medium">
            <div id="rj-cover">
              <a href="#">
                <img src="" />
              </a>
            </div>
            <div class="info">
              <div class="rjp-trackinfo-container">
                <h4 class="rjp-label">Now playing:</h4>
                <p id="trackInfo" class="rjp-info"></p>
              </div>
              <div class="rjp-player-container">
                <div id="rjp-radiojar-player"></div>
                <div id="rj-player-controls" class="rj-player-controls">
                  <div class="jp-gui jp-interface">
                    <div class="jp-controls">
                      <a
                        href="javascript:;"
                        style={{ display: "block" }}
                        class="jp-play"
                        title="Play"
                      >
                        {/* <i class="icon-play"></i> */}
                        <i className="icon-play far fa-play-circle" />
                      </a>
                      <a
                        href="javascript:;"
                        style={{ display: "none" }}
                        class="jp-pause"
                        title="Pause"
                      >
                        {/* <i class="icon-pause"></i> */}
                        <i className="icon-pause far fa-pause-circle" />
                      </a>
                      <a
                        href="javascript:;"
                        style={{ display: "block" }}
                        class="jp-mute"
                        title="Mute"
                      >
                        <i class="icon-volume-up"></i>
                      </a>
                      <a
                        href="javascript:;"
                        style={{ display: "none" }}
                        class="jp-unmute"
                        title="Unmute"
                      >
                        <i class="icon-volume-off"></i>
                      </a>
                      <div class="jp-volume-bar-wrapper">
                        <div class="jp-volume-bar">
                          <div class="jp-volume-bar-value"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AudioPlayerStyles>
    </>
  )
}
export default AudioPlayer
