import React, { useState } from "react"
import styled from "styled-components"

const StyledPlayer = styled.div`
  i {
    font-size: 1.5em;
  }
`

const MusicPlayerSmall = props => {
  const [playButton, setPlayButton] = useState("fas fa-play-circle")

  function ToggleAudio() {
    document.getElementById("player").play()
    setPlayButton("fas fa-pause-circle")
    if (playButton === "fas fa-pause-circle") {
      document.getElementById("player").pause()
      setPlayButton("fas fa-play-circle")
    }
  }
  return (
    <StyledPlayer>
      <div id="audio-content">
        <audio style={{ display: "none" }} id="player">
          <source src={props.audio} />
        </audio>
        <div id="play-btn" onClick={ToggleAudio}>
          <i className={playButton} />
        </div>
      </div>
    </StyledPlayer>
  )
}

export default MusicPlayerSmall
