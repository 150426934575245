import React from "react"
import AudioPlayer from "../components/AudioPlayer"
import AudioPlayerTemp from "../components/AudioTempPlayer"
import Layout from "../components/layout"
import MusicPlayerSmall from "../components/MusicPlayerSmall"
import Moment from "react-moment"

const TestPage = () => {
  const time = "2020-06-24T15:00:00.000Z "
  return (
    <div>
      <Layout>
        <div style={{ marginTop: "100px" }}>
          <Moment format="HH:MM">{time}</Moment>
        </div>
      </Layout>
    </div>
  )
}

export default TestPage
