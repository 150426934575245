import styled from "styled-components"
import LoadingAnimation from "../../images/loading.gif"

export const AudioPlayerStyles = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  #rj-player {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 130px;

    #rj-cover {
      position: absolute;
      background-color: blue;
      width: 130px;
      height: 130px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      max-width: 140px;
      position: absolute;
      left: 150px;
      h4 {
        color: yellow;
        font-size: 1.2em;
        font-family: "Orbitron", sans-serif;
        margin: 10px 0 0 0;
      }
      p {
        color: white;
        font-size: 1em;
        margin: 10px 0 0 0;
        font-family: "Roboto", sans-serif;
      }
    }
    .fa-play-circle {
      position: absolute;
      left: 150px;
      top: 40px;
      font-size: 3.5em;
      color: whitesmoke;
      &:hover {
        color: yellow;
      }
    }
    .fa-pause-circle {
      position: absolute;
      left: 150px;
      top: 40px;
      font-size: 3.5em;
      color: whitesmoke;
      &:hover {
        color: yellow;
      }
    }
  }
  #rj-player .rjp-player-container .jp-play.player-loading,
  #rj-player .rjp-player-container .jp-pause.player-loading {
    background-image: url(${LoadingAnimation});
    background-position: center;
    background-repeat: no-repeat;
  }
`
